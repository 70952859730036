import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Her Story",
  "date": "2015-07-06T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "620px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "60%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAwD/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABQzBhJKj/xAAaEAEAAgMBAAAAAAAAAAAAAAABAAIDESES/9oACAEBAAEFAjUw2FtXq8Dy7n//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8Bka//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8BtY//xAAYEAEAAwEAAAAAAAAAAAAAAAABABAhMv/aAAgBAQAGPwKI7OSsr//EABkQAQEBAQEBAAAAAAAAAAAAAAEAESExcf/aAAgBAQABPyE4OZeqI3s7sD5NzZLFX//aAAwDAQACAAMAAAAQMw//xAAWEQEBAQAAAAAAAAAAAAAAAAARAAH/2gAIAQMBAT8Q2GV//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EAGxf//EABoQAQADAQEBAAAAAAAAAAAAAAEAESExUWH/2gAIAQEAAT8Qxxoa32IklULLGLwfiyDRaE1gKm+6/Ypujk//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "her story",
          "title": "her story",
          "src": "/static/65b41cf022982cd2e78562374e1d7f34/935bc/her-story.jpg",
          "srcSet": ["/static/65b41cf022982cd2e78562374e1d7f34/35f54/her-story.jpg 275w", "/static/65b41cf022982cd2e78562374e1d7f34/d7854/her-story.jpg 550w", "/static/65b41cf022982cd2e78562374e1d7f34/935bc/her-story.jpg 620w"],
          "sizes": "(max-width: 620px) 100vw, 620px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`I `}<a parentName="p" {...{
        "href": "https://twitter.com/vernalkick/status/617362664403156993"
      }}>{`tweeted about it already`}</a>{`, but a single tweet isn’t enough to express how much I enjoyed `}<a parentName="p" {...{
        "href": "http://www.herstorygame.com"
      }}>{`this game`}</a>{`. I’m not a gamer`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{`. I have very few games on my iPhone and I rarely download new ones, but if you’re like me, you’re going to love this one. Describing ”`}<a parentName="p" {...{
        "href": "http://www.herstorygame.com"
      }}>{`Her Story`}</a>{`” as a game almost doesn’t do it justice; it’s much more like an interactive movie.`}</p>
    <p>{`By simply typing keywords in a search box, you slowly uncover the mysterious story of a British woman interviewed about her missing husband. Each video giving you a better idea of what happened.`}</p>
    <p>{`The result is just a fascinating experience that I’ve never seen in a game before. I can’t recommend `}<a parentName="p" {...{
        "href": "http://www.herstorygame.com"
      }}>{`this game`}</a>{` enough.`}</p>
    <p><a parentName="p" {...{
        "href": "http://itunes.com/apps/sambarlow/herstory"
      }}>{`Buy it on the App Store`}</a></p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`Mario Kart doesn’t count, right?`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      